
import { defineComponent, ref, computed, onMounted, inject, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { hideCaptchaBadge, ParseJwt } from "@/core/helpers/helps";
import { detectSubDomain } from "@/core/helpers/helps";
import { redirectLogin } from "@/core/helpers/enum";
import ErrorResponseModal from "@/components/modals/signin-page/ErrorResponseModal.vue";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    ErrorResponseModal
  },
  inject: ["messaging"],
  head() {
    return {
      title: 'sign-in',
      meta: [
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const messaging = inject("messaging") as any;
    const statusError = ref(false);
    const errorMessage = ref('');
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    //Create form validation object

    let counter_fail = ref(0) as any
    const login = Yup.object().shape({
      username: Yup.string()
        .required("This field is required")
        .label(t("signInPage.formLabelEmail")),
      password: Yup.string()
        .min(4)
        .required()
        .label(t("signInPage.formLabelPassword")),
    });
    const domain = ref(detectSubDomain());
    onMounted(() => {
      setCurrentPageTitle("Sign In");
      if (localStorage.getItem("7hb") === null) {
        localStorage.setItem('7hb', counter_fail.value)
      } else {
        counter_fail.value = localStorage.getItem("7hb")
      }

      if (localStorage.getItem("rem") !== null) {
        setInterval(() => { countdown() }, 1000);
      }
    });

    const errors = computed(() => {
      return store.getters.getErrors;
    });

    const reCaptcha = useReCaptcha();
    //Form submit function
    const onSubmitLogin = async (values) => {
      counter_fail.value = localStorage.getItem("7hb")
      if(!reCaptcha){
        errorMessage.value = 'Could not get Recaptcha';
        statusError.value = true;
        submitButton.value?.removeAttribute("data-kt-indicator");
        return console.log("Could not get Recaptcha")
      }
      await reCaptcha.recaptchaLoaded()
      const token = await reCaptcha.executeRecaptcha('login')

      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store
        .dispatch(Actions.LOGIN, {
          ...values,
          recaptchaToken: token
        })
        .then((res) => {
          if (res.success) {
            localStorage.removeItem("7hb");
            localStorage.removeItem("rem");
            hideCaptchaBadge('hidden');
            router.push({
              path: '/assets/list',
            });
          }
        })
        .catch((error) => {
          counter_fail.value = localStorage.getItem("7hb") === null ? 0 : Number(localStorage.getItem("7hb"))
          localStorage.setItem('7hb', counter_fail.value + 1)
          if (counter_fail.value === 4) {
            const now = new Date();
            const countDownToTime = new Date().setTime(now.getTime() + 1 * 60000)

            localStorage.setItem('rem', String(countDownToTime))
            setInterval(() => { countdown() }, 1000);
          }
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    function toDashboard() {
      router.push("/dashboard");
    }

    watch(
      () => errors.value,
      (after, before) => {
        if (after.data) {
          errorMessage.value = after.data.msg;
          statusError.value = true;
          submitButton.value?.removeAttribute("data-kt-indicator");
        }
      }
    );

    const timerOutput = ref(null) as any

    function handleClose(type: string): void {
     statusError.value = false;
    }


    const countdown = () => {
      const timeNow = new Date().getTime();
      const timeDifference = Number(localStorage.getItem("rem")) - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingSeconds =Math.floor(remainderDifferenceInSeconds) + 2;
      timerOutput.value = remainingSeconds < 1 ? "Terlalu banyak melakukan percobaan login , silahkan coba login kembali setelah " + 0 + " detik" : "Terlalu banyak melakukan percobaan login , silahkan coba login kembali setelah " + remainingSeconds + " detik";
      if (remainingSeconds === 0) {
        timerOutput.value = "Terlalu banyak melakukan percobaan login , silahkan coba login kembali setelah " + 0 + " detik";
        localStorage.removeItem("rem");
        localStorage.removeItem("7hb");
        window.location.reload()
      }
    }

    const showPassword = ref(false);
    const togglePassword = () => (showPassword.value = !showPassword.value);

    return {
      detectSubDomain,
      handleClose,
      showPassword,
      togglePassword,
      domain,
      toDashboard,
      onSubmitLogin,
      counter_fail,
      timerOutput,
      login,
      submitButton,
      errors,
      statusError,
      errorMessage
    };
  },
});
